export const ESVApiKey = 'Token 82d67ee5f2ea7f438db3c1343fafa038763eb1e3';
export const firebaseConfig = {
	apiKey: 'AIzaSyC0yvDfLeWbTAVQgQs_9KNHw5WFdxAuAm8',
	authDomain: 'bible-memorization-app.firebaseapp.com',
	databaseURL: 'https://bible-memorization-app.firebaseio.com',
	projectId: 'bible-memorization-app',
	storageBucket: 'bible-memorization-app.appspot.com',
	messagingSenderId: '74200088440',
	appId: '1:74200088440:web:f14ef6ce8f2acd1b81128d',
	measurementId: 'G-4T8KJ59F7R',
};
export const emailAPIKey = '5Hkq1FUSPNJbJuJzNNUuAv8fcFlLIKA0';
export const emailAPIUrl = 'https://usebasin.com/f/988c59d2b10a';